import { useEffect, useCallback} from 'react';

import { useTranslation } from 'react-i18next';
import { BrowserRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { createRoot } from 'react-dom/client';
import './i18n';


const App = () => {
  const { search } = useLocation();
  //getting the tracking number and json in URL
  const { trackingNumber, JSON:jsonParam } = queryString.parse(search);
  const { t, i18n } = useTranslation();



  const getPathLanguage = () => {
    const path = window.location.pathname;
    const segments = path.split('/');
    const langCode = segments[1];
    return langCode;
  };


  var browserLanguage = getPathLanguage();


  useEffect(() => {
    if (/^U.*/.test(trackingNumber)) {
      i18n.changeLanguage('sv');
    } else {
      if (browserLanguage === 'dk') {
        browserLanguage = 'da';
      }
      if (browserLanguage === 'se') {
        browserLanguage = 'sv';
      }
      if (browserLanguage !== i18n.language) {
        i18n.changeLanguage(browserLanguage);
      }
    }
  }, [i18n, browserLanguage, trackingNumber]);

  const getApiData = useCallback(() => {
    const fetchData = async () => {
      let data = null;
      let carrier = null;

    if (!trackingNumber ) {
      return`
      <div class='tracking-text-cont'>
        <div class='tracking-error-cont'>
          <div>${t('order_error')}</div>
        </div>
        <hr class='tracking-hr' />
        <div class='tracking-cs-cont'>
          <div class='cs-header'>${t('cs_header')}</div>
          <div>${t('cs_text')}</div>
          <button class='tracking-cs-button' onclick="window.location.href='https://miss-mary-of-sweden.elevio.help/'">${t('cs_button')}</button>
        </div>
      </div>
      `
    }

    if (/^U.*/.test(trackingNumber)) {
      try {
        var browserLanguage = 'sv';
        const postnordResponse = await fetch(`https://asendiatracking.azurewebsites.net/api/postnordfull?code=tS1Hisghk0bmbjB38m1y816KH7PA0Qtt_rP99BtmG5LeAzFuF0VL9A%3D%3D&tracking=${trackingNumber}&locale=${browserLanguage}`);
        const postnordData = await postnordResponse.json();
        carrier =  `https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall?shipmentId=${trackingNumber}`;

        if (postnordData.events.length > 0) {
          data = postnordData;
        }
      } catch (error) {
        carrier =  `https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall?shipmentId=${trackingNumber}`;
      }
    } else if (/^.{15,}$/.test(trackingNumber)) {
      try {
        const dhlResponse = await fetch(`https://asendiatracking.azurewebsites.net/api/dhlfull?code=tzT61L0Yd0lqz9cwyHPElFbEfwXbpkNw_N0tGUzZqtC5AzFuBmCeQw%3D%3D&trackingNumber=${trackingNumber}&language=${browserLanguage}`);
        const dhlData = await dhlResponse.json();
        carrier = `https://www.dhl.com/se-en/home/tracking.html?tracking-id=${trackingNumber}`;

        if (dhlData.events.length > 0) {
          data = dhlData;
        }
      } catch (error) {
        carrier = `https://www.dhl.com/se-en/home/tracking.html?tracking-id=${trackingNumber}`;
      }
    } else {
      try {
        const asendiaResponse = await fetch(`https://asendiatracking.azurewebsites.net/api/asendiafull?code=bPjsoAjRnInqcqbd2IAZpSAmLTA2lMO-Xm7D9M4Tc-RkAzFuFeaSiA%3D%3D&tracking=${trackingNumber}&language=${i18n.language}`);
        const asendiaData = await asendiaResponse.json();
        carrier = `https://tracking.asendia.com/tracking/${trackingNumber}`;

        if (asendiaData.events.length > 0) {
          data = asendiaData;
        }
      } catch (error) {
        carrier = `https://tracking.asendia.com/tracking/${trackingNumber}`;
      }
    } if (!data || !data.status) {
      return `
        <div class='tracking-text-cont'>
          <div class='tracking-error-cont'>
            <div>${t('order_error')}</div>
          </div>
          <hr class='tracking-hr' />
          <div class='tracking-more-cont'>
            <div>${t('order_number')}: </div>
            <div class='carrier_tracking_number'> ${trackingNumber}</div>
            <div>${t('carrier_text')}</div>
            <a class='track-button' href=${carrier}>${t('carrier_track')}</a>
          </div>
          <hr class='tracking-hr' />
          <div class='tracking-cs-cont'>
            <div class='cs-header'>${t('cs_header')}</div>
            <div>${t('cs_text')}</div>
            <button class='tracking-cs-button' onclick="window.location.href='https://miss-mary-of-sweden.elevio.help/'">${t('cs_button')}</button>
          </div>
        </div>
        `;
    }
    if (data) {
      if (jsonParam === 'true') {
        //if JSON
        const jsonData = JSON.stringify(data, null, 2);
        return jsonData;
      } else {
        const table = createTable(data, carrier);
        return table;
      }
    };
  };
  return fetchData();
  }, [trackingNumber, t]);

  const updateTrackingInfo = async () => {
    const targetDiv = document.getElementById('tracking-info');
    if (targetDiv) {
      targetDiv.innerHTML = "<div class='spinner-wrapper'><div class='spinner'/></div>";
      const trackingData = await getApiData();
      targetDiv.innerHTML = trackingData;
    }
  };

  useEffect(() => {
      updateTrackingInfo();
  });

  const createTable = (data, carrier) => {
    let initialRows = data.events.slice(0, 3).map((item, index) => {
      let rowClass = index === 0 ? 'tracking-status' : '';
      return `<tr class="${rowClass}"><td class='table-time'>${item.Time.split(',').join('<br>')}</td><td class='table-event'>${item.Event}</td><td class='table-location'>${item.Location.toLowerCase()}</td></tr>`;
    }).join('');
    let showAllRows =  data.events.map((item, index) => {
      let rowClass = index === 0 ? 'tracking-status' : '';
      return `<tr class="${rowClass}"><td class='table-time'>${item.Time.split(',').join('<br>')}</td><td class='table-event'>${item.Event}</td><td class='table-location'>${item.Location.toLowerCase()}</td></tr>`;
    }).join('');
    let isShowingMore = false;

    const handleMoreInfoTextClick = () => {
      if (isShowingMore) {

        isShowingMore = false;
        let remainingRowsCount = data.events.length - 3;

        document.getElementById('moreInfoText').innerText = `${t('show_all_info')} (${remainingRowsCount})`;
        document.querySelector('.tracking-table tbody').innerHTML = initialRows;
      } else {
        isShowingMore = true;
        document.getElementById('moreInfoText').innerText = t('show_less_info');
        document.querySelector('.tracking-table tbody').innerHTML = showAllRows;
      }
    };


    let remainingRowsCount = data.events.length - 3;

    let tableHMTL = `
    <div class='tracking-title'>${t('order_tracking')}</div>
    <div class='tracking-main'>
    <div class='tracking-container'>
    <div class='tracking-header'>${t('order_status')}</div>
    <table class='tracking-table'>
    <tbody>
    ${initialRows}
    </tbody>
    </table>
    <a id='moreInfoText'>${`${t('show_all_info')} (${remainingRowsCount}`}) </a>
      </div>
    </div>
    <div class='tracking-text-cont'>
      <div class='tracking-more-cont'>
        <div>${t('order_number')}: </div>
        <div class='carrier_tracking_number'> ${trackingNumber}</div>
        <div>${t('carrier_text')}</div>
        <a class='track-button' href=${carrier}>${t('carrier_track')}</a>
      </div>
      <hr class='tracking-hr' />
      <div class='tracking-cs-cont'>
        <div class='cs-header'>${t('cs_header')}</div>
        <div>${t('cs_text')}</div>
        <button class='tracking-cs-button' onclick="window.location.href='https://miss-mary-of-sweden.elevio.help/'">${t('cs_button')}</button>
      </div>
    </div>
  `;

  document.body.innerHTML = tableHMTL;

  document.getElementById('moreInfoText').addEventListener('click', handleMoreInfoTextClick);

  return tableHMTL;
  };
  return null;
};




const RouterApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default RouterApp;

window.onload = function() {

  const root = document.createElement('div');
  document.body.appendChild(root);
  const reactRoot = createRoot(root);
  reactRoot.render(<RouterApp />);
};
