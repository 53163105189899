import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationDA from './translations/da/lang.json'
import translationDE from './translations/de/lang.json'
import translationEN from './translations/en/lang.json'
import translationFI from './translations/fi/lang.json'
import translationFR from './translations/fr/lang.json'
import translationNL from './translations/nl/lang.json'
import translationSV from './translations/sv/lang.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    sv: {
      translation: translationSV,
    },
    en: {
      translation: translationEN,
    },
    de: {
      translation: translationDE,
    },
    fi: {
      translation: translationFI,
    },
    nl: {
      translation: translationNL,
    },
    fr: {
      translation: translationFR,
    },
    da: {
      translation: translationDA,
    },
  },
})

export default i18n
